import { Component, Input } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { CreateMicroservicePage } from 'src/app/_modals/create-microservice/create-microservice.page';
import { MicroservicesPage } from 'src/app/_modals/microservices/microservices.page';
import { Events } from 'src/app/_service/events';
import { AuthService } from 'src/app/_service/keycloakAuth.service';
import { UtilityService } from 'src/app/_service/utility.service';
import { GV } from 'src/app/globalVariable';
@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SidemenuComponent {
  showReports: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    public utilityService: UtilityService,
    private authService: AuthService,
    private navCtrl: NavController,
    private event: Events,
  ) {
  }

  home() {
    this.utilityService.mainMenu = "home"
    this.navCtrl.navigateForward('/home')
  }

  /*  opening microservice modal */

  async createMS() {
    this.utilityService.mainMenu = "createMS"
    const modal = await this.modalCtrl.create({
      component: CreateMicroservicePage,
      componentProps: {
        type: 'create'
      },
      cssClass: 'modal',
      backdropDismiss: false,
    });
    modal.onDidDismiss().then(data => {
      if (data.data == 'noChange') { return }
      this.navCtrl.navigateRoot('/home')
      this.event.publish('reloadHome')
    })
    return await modal.present();
  }

  async endPoints() {
    if (this.utilityService.mainMenu !== "endpoints") {
      this.utilityService.mainMenu = "endpoints"
      const modal = await this.modalCtrl.create({
        component: MicroservicesPage,
        componentProps: {
          fromPage: 'endpoint'
        },
        cssClass: 'listModal',
        backdropDismiss: false,
      });
      modal.onDidDismiss().then(data => {
        if (data?.data == 'createMicroServices') {
          this.createMS()
        }
      })
      return await modal.present();
    }
  }

  publish() {
    this.utilityService.mainMenu = "publish"
    this.navCtrl.navigateForward('/publish')
  }

  deploy() {
    this.utilityService.mainMenu = "deploy"
    this.navCtrl.navigateForward('/deploy')
  }

  async authorize() {
    this.utilityService.mainMenu = "authorize"
    this.navCtrl.navigateForward('/authorize')
  }

  userList() {    
    this.utilityService.mainMenu = "userList"
    this.navCtrl.navigateForward('/userList')
  }

  endpointReport() {
    this.utilityService.mainMenu = "endpointReport"
    this.navCtrl.navigateForward('/Reports/endpointReport')
  }

  msReport() {
    this.utilityService.mainMenu = "msReport"
    this.navCtrl.navigateForward('/Reports/msReport')
  }

  auditReport() {
    this.utilityService.mainMenu = "auditReport"
    this.navCtrl.navigateForward('/Reports/versionReport')
  }

  logout() {
    this.authService.logout()
  }

  expandReports() {
    this.showReports = !this.showReports
  }
}
