import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/_service/api.service';
import { AuthService } from 'src/app/_service/keycloakAuth.service';
import { UtilityService } from 'src/app/_service/utility.service';
import { ENUMS } from 'src/app/ENUMS';

@Component({
  selector: 'app-usermanagement',
  templateUrl: './usermanagement.page.html',
  styleUrls: ['./usermanagement.page.scss'],
})
export class UserManagementPage implements OnInit {
  user: any;
  microservices: any[];
  entity: string;
  role: string;
  userRoles = ['ADMIN', 'MAKER', 'CHECKER'];
  entityNames = ['VAYANA', 'RUBIX', 'HYLOBIZ'];
  checkedAndUncheckedMicroservices: any[];
  checkedMs = new Map();
  loading: boolean = false;

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    public utilityService: UtilityService,
    private apiService: ApiService,
    private authService: AuthService,
  ) {
    this.user = this.navParams.get('user')
    this.microservices = this.navParams.get('microservices')
    this.user.microservices?.forEach(ms => {
      this.checkedMs.set(ms.id, true);
    })
    this.entity = this.user.entity_name
    this.role = this.user.user_type
  }


  handleCheckboxChange(checked, e) {
    if (!e.detail.checked) {
      this.checkedMs.delete(checked.id);
    } else {
      this.checkedMs.set(checked.id, true);
    }
  }

  selectRole(e) {
    this.role = e.detail.value
  }

  selectEntity(e) {
    this.entity = e.detail.value
  }
  goBack() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {
  }

  async saveForm() {
    var microserviceids = []
    this.checkedMs.forEach((value, key) => {
      if (value) {
        microserviceids.push(key)
      }
    });
    var requestObj = {
      user_type: this.role,
      "entity_name": this.entity,
      "microservices": microserviceids,
    }
    this.loading = true;
    let ld = await this.loadingCtrl.create(ENUMS.LoaderConfig);
    ld.present().then(() => {
      this.apiService.userEdit(requestObj, this.user.id)
        .then((data: { data: any; }) => {
          const response = JSON.parse(data.data)
          if (response.status !== 'success') {
            this.loading = false;
            this.utilityService.showErrMsgTop(response.Message);
            return
          }
          this.utilityService.mainMenu = "home"
          this.utilityService.showSuccessMsgTop(response.message);
          ld.dismiss();
          this.loading = false;
          this.modalCtrl.dismiss();
          return null
        }, (err: any) => {
          ld.dismiss();
          this.loading = false;
          let error = JSON.parse(err.data)
          if (error?.error?.errors?.errorCode == "err-auth-token-is-invalid") {
            this.utilityService.showErrMsgTop('Session timed out! Please login again')
            this.utilityService.showMenu = false
            this.authService.logout()
            return
          }
          this.utilityService.showErrMsgTop(error?.error?.errors?.errorCode)
        });
    });
  }
}
