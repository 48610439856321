import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { CreateMicroservicePage } from './_modals/create-microservice/create-microservice.page';
import { SidemenuComponent } from './_components/sidemenu/sidemenu.component';
import { HeaderComponent } from './_components/header/header.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouteReuseStrategy } from '@angular/router';
import { NgModule } from '@angular/core';
import { MicroservicesPage } from './_modals/microservices/microservices.page';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { JsonViewPage } from './_modals/json-view/json-view.page';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RejectmodalPage } from './_modals/rejectmodal/rejectmodal.page';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { SharedModule } from '../app/_shared/shared-module';
import { MicroservicesNamePage } from './_modals/microservicesname-view/microservicesname-view.page';
import { UserManagementPage } from './_modals/usermanagement/usermanagement.page';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    CreateMicroservicePage,
    MicroservicesPage,
    SidemenuComponent,
    JsonViewPage,
    MicroservicesNamePage,
    UserManagementPage,
    HeaderComponent,
    RejectmodalPage
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    BackButtonDisableModule.forRoot(),
    IonicModule.forRoot({
      swipeBackEnabled: false,
      rippleEffect: true,
      mode: 'ios'
    }),
    SharedModule,
    IonicModule.forRoot({}),],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
