import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-microservicesname-view',
  templateUrl: './microservicesname-view.page.html',
  styleUrls: ['./microservicesname-view.page.scss'],
})

export class MicroservicesNamePage implements OnInit {
  microservices: any[];

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController
  ) {
    this.microservices = this.navParams.get('microservices')
  }

  goBack() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {
  }

}
