import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard, InitGuard } from './_helpers/app.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
    canActivate: [InitGuard]
  },
  {
    path: 'endpoint',
    loadChildren: () => import('./endpoints/endpoints.module').then(m => m.EndpointsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'microservice',
    loadChildren: () => import('./microservice-detail/microservice-detail.module').then(m => m.MicroserviceDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'authorize',
    loadChildren: () => import('./authorize/authorize.module').then(m => m.AuthorizePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'publish',
    loadChildren: () => import('./publish/publish.module').then(m => m.PublishPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'deploy',
    loadChildren: () => import('./deploy/deploy.module').then(m => m.DeployPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'Reports/msReport',
    loadChildren: () => import('./Reports/microserviceReport/report.module').then(m => m.ReportPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'Reports/endpointReport',
    loadChildren: () => import('./Reports/endpoint-report/endpoint-report.module').then(m => m.EndpointReportPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'Reports/versionReport',
    loadChildren: () => import('./Reports/audit-report/audit-report.module').then(m => m.AuditReportPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'userList',
    loadChildren: () => import('./user-list/user-list.module').then(m => m.UserListPageModule),
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
